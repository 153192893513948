// Global Imports
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import _ from 'lodash';

// Project Imports
import KiButton from 'components/KiButton';
import KiInput from 'components/KiInput';
import KiCheckbox from 'components/KiCheckbox';
import KiSelect from 'components/KiSelect';
import KiProgressBar from 'components/KiProgressBar';
import {fundingAnalysisApi, fundingVehiclesApi, datasetDatesApi} from 'api';
import {useMergedState} from 'utils/customHooks';
import {debtScenarioContexts} from 'ki-common/options/debt';

// Relative Imports
import styles from './cardForm.theme.scss';

const scenarioContextOptions = [].concat(debtScenarioContexts);

function FundingAnalysisCardForm({
	baseCard,
	existingTags,
	mode,
	onDialogClose,
	saveCard,
	deleteCard,
	isDeleteDisabled,
	isSaveDisabled,
}) {
	const [isLoading, setIsLoading] = useState(true);
	const [name, setName] = useState(baseCard.name);
	const [nameError, setNameError] = useState();
	const [tags, setTags] = useState(baseCard.tags || []);
	const [cardSettings, setCardSetting] = useMergedState({...baseCard.settings});
	const [scenarioError, setScenarioError] = useState();
	const [fundingVehicleError, setFundingVehicleError] = useState();
	const [scenarioList, setScenarioList] = useState([]);
	const [dateContextList, setDateContextList] = useState([]);
	const [fundingVehicleList, setFundingVehicleList] = useState([]);
	const [bookmark, setBookmark] = useState();

	const explorationConstraintTypes = [{value: 'setup', label: 'FV Defined'}, {value: 'applied', label: 'Applied'}];

	// On load
	useEffect(
		() => {
			const fetchInitialLoadData = async () => {
				setIsLoading(true);
				// Fetch all scenarios
				const scenarioData = await fundingAnalysisApi.fetchScenarios(baseCard.datasetId);
				setScenarioList(
					scenarioData.map(scenario => {
						return {
							value: scenario._id,
							label: scenario.name,
						};
					})
				);
				if (cardSettings.tableType === 'scenario' && !cardSettings.scenarioId) {
					setCardSetting({scenarioId: scenarioData[0]._id});
				}

				// Fetch date context options
				const dates = await datasetDatesApi.fetchPortfolioDates(baseCard.datasetId);
				setDateContextList(dates.filter(d => d.readOnly));

				// Fetch funding models and filter down to name and ID
				const fvs = await fundingVehiclesApi.fetchFundingVehicleList(baseCard.datasetId);
				const fvOptions = fvs.map(fv => {
					return {
						fvName: fv.name,
						fvId: fv._id,
					};
				});
				setFundingVehicleList(fvOptions);

				const currentBookmark = await fundingAnalysisApi.fetchBookmarkById(
					baseCard.settings.fundingAnalysisBookmarkId
				);
				setBookmark(currentBookmark);
				setIsLoading(false);
			};
			fetchInitialLoadData();
		},
		[baseCard]
	);

	const saveChanges = () => {
		// Verify scenario for scenario types
		if (cardSettings.tableType === 'scenario') {
			if (_.isEmpty(cardSettings.scenarioId)) {
				setScenarioError('A scenario is required');
			} else {
				setScenarioError('');
			}
		}

		// Verify funding vehicle for breaches and excess
		if (['breaches', 'excess'].includes(cardSettings.viewType)) {
			if (_.isEmpty(cardSettings.fundingVehicleIds)) {
				setFundingVehicleError('A funding vehicle is required');
			} else {
				setFundingVehicleError('');
			}
		}

		if (!_.isEmpty(fundingVehicleError) || !_.isEmpty(scenarioError)) {
			return null;
		}
		const newCard = Object.assign({}, baseCard, {name}, {tags});
		newCard.settings = cardSettings;
		saveCard(newCard);
	};

	const close = () => {
		if (mode === 'dialog') {
			onDialogClose();
		}
	};

	const getFundingVehicleSection = () => {
		const cardFVIds = cardSettings.fundingVehicleIds || [];

		// Must select a specific FV
		if (
			['borrowingBase'].includes(cardSettings.tableType) ||
			['breaches', 'excess', 'pool'].includes(cardSettings.viewType)
		) {
			return (
				<React.Fragment>
					<div className={styles.formSelectSection}>
						<span className="form-instruction">Funding Vehicle</span>
						<KiSelect
							classNamePrefix="aut-select"
							closeOnSelect={true}
							isClearable={false}
							isMulti={false}
							onChange={fvs => {
								setCardSetting({fundingVehicleIds: !fvs ? [] : [fvs.fvId]});
								setFundingVehicleError();
							}}
							options={fundingVehicleList}
							value={fundingVehicleList.filter(option => cardFVIds.includes(option.fvId))}
							getOptionLabel={option => option.fvName}
							getOptionValue={option => option.fvId}
							errorMessage={fundingVehicleError}
						/>
					</div>
					<div className={styles.formSelectSection}>
						<KiCheckbox
							name="useReportFundingVehicle"
							checked={cardSettings.useReportFundingVehicle}
							label="Report overrides funding vehicle"
							onChange={val => setCardSetting({useReportFundingVehicle: val})}
						/>
					</div>
				</React.Fragment>
			);
		}
		return (
			// Can select several FV's or all
			<React.Fragment>
				<div className={styles.formSelectSection}>
					<span className="form-instruction">Funding Vehicle</span>
					<KiSelect
						classNamePrefix="aut-select"
						closeOnSelect={true}
						isClearable={false}
						isMulti={true}
						placeholder={'All'}
						onChange={fvs => {
							setCardSetting({fundingVehicleIds: !fvs ? [] : fvs.map(fv => fv.fvId)});
						}}
						options={fundingVehicleList}
						value={fundingVehicleList.filter(option =>
							cardSettings.fundingVehicleIds.includes(option.fvId)
						)}
						getOptionLabel={option => option.fvName}
						getOptionValue={option => option.fvId}
						errorMessage={fundingVehicleError}
					/>
				</div>
				<div className={styles.formSelectSection}>
					<KiCheckbox
						name="useReportFundingVehicle"
						checked={cardSettings.useReportFundingVehicle}
						label="Report overrides funding vehicles"
						onChange={val => setCardSetting({useReportFundingVehicle: val})}
					/>
				</div>
			</React.Fragment>
		);
	};

	const getCardSettingsColumn = () => {
		return (
			<React.Fragment>
				{getFundingVehicleSection()}
				{['eligibility', 'summary'].includes(cardSettings.tableType) && (
					<div className={styles.formSelectSection}>
						<span className="form-instruction">Date Context</span>
						<KiSelect
							getOptionLabel={option => option.name}
							getOptionValue={option => option._id}
							classNamePrefix="aut-select"
							closeOnSelect={true}
							isClearable={false}
							options={dateContextList}
							value={dateContextList.find(date => date._id === cardSettings.dateContext)}
							onChange={date => setCardSetting({dateContext: date._id})}
						/>
					</div>
				)}
				{cardSettings.tableType === 'summary' && (
					<React.Fragment>
						<div className={styles.formSelectSection}>
							<span className="form-instruction">Scenario Context</span>
							<KiSelect
								classNamePrefix="aut-select"
								closeOnSelect={true}
								isClearable={false}
								options={scenarioContextOptions}
								value={scenarioContextOptions.find(
									option => option.value === cardSettings.scenarioType
								)}
								getOptionLabel={option => option.label}
								getOptionValue={option => option.value}
								onChange={val => setCardSetting({scenarioType: val.value})}
							/>
						</div>
						<div className={styles.formSelectSection}>
							<KiCheckbox
								name="useReportScenarioId"
								checked={cardSettings.useReportScenarioId}
								label="Report overrides Scenario Context"
								onChange={val => setCardSetting({useReportScenarioId: val})}
							/>
						</div>
						<div className={styles.formSelectSection}>
							<KiCheckbox
								name="isBlended"
								checked={cardSettings.isBlended}
								label="Blended"
								onChange={val => setCardSetting({isBlended: val})}
							/>
						</div>
					</React.Fragment>
				)}
				{cardSettings.tableType === 'scenario' && (
					<React.Fragment>
						<div className={styles.formSelectSection}>
							<span className="form-instruction">Scenario</span>
							<KiSelect
								classNamePrefix="aut-select"
								closeOnSelect={true}
								isClearable={false}
								options={scenarioList}
								value={scenarioList.find(option => option.value === cardSettings.scenarioId)}
								getOptionLabel={option => option.label}
								getOptionValue={option => option.value}
								onChange={selected => {
									setCardSetting({scenarioId: selected.value});
									setScenarioError();
								}}
								errorMessage={scenarioError}
							/>
						</div>
						<div className={styles.formSelectSection}>
							<KiCheckbox
								name="useReportScenarioId"
								checked={cardSettings.useReportScenarioId}
								label="Report overrides scenario"
								onChange={val => setCardSetting({useReportScenarioId: val})}
							/>
						</div>
						<div className={styles.formSelectSection}>
							<span className="theme-label">Constraints</span>
							<KiSelect
								classNamePrefix="aut-select"
								value={explorationConstraintTypes.find(t => t.value === cardSettings.constraintGroup)}
								options={explorationConstraintTypes}
								onChange={val => setCardSetting({constraintGroup: val.value})}
							/>
						</div>
					</React.Fragment>
				)}
				{cardSettings.tableType === 'borrowingBase' && (
					<React.Fragment>
						<div className={styles.formSelectSection}>
							<span className="form-instruction">Scenario Context</span>
							<KiSelect
								classNamePrefix="aut-select"
								closeOnSelect={true}
								isClearable={false}
								options={scenarioContextOptions}
								value={scenarioContextOptions.find(
									option => option.value === cardSettings.scenarioType
								)}
								getOptionLabel={option => option.label}
								getOptionValue={option => option.value}
								onChange={val => setCardSetting({scenarioType: val.value})}
							/>
						</div>
						<div className={styles.formSelectSection}>
							<KiCheckbox
								name="useReportScenarioId"
								checked={cardSettings.useReportScenarioId}
								label="Report overrides Scenario Context"
								onChange={val => setCardSetting({useReportScenarioId: val})}
							/>
						</div>
					</React.Fragment>
				)}
			</React.Fragment>
		);
	};

	return isLoading ? (
		<KiProgressBar />
	) : (
		<div className="context-sidebar-panel-flex column-selector-form">
			<div>
				{!mode === 'dialog' && <header className="flyout-panel-title">MANAGE CARDS</header>}
				<section className={mode === 'dialog' ? styles.cardFormDialog : ''}>
					<div className={styles.column}>
						{bookmark && (
							<div className={styles.infoSection}>
								<span className="form-instruction">View Name</span>
								{bookmark.name}
							</div>
						)}
						<div className={styles.formInputSection}>
							<KiInput
								label="Name"
								value={name}
								onChange={val => {
									//TODO const error = checkNameValid([], val, baseCard._id);
									setNameError();
									setName(val);
								}}
								error={nameError}
							/>
						</div>
						<div className={styles.formSelectSection}>
							<span className="form-instruction">Display Type:</span>
							<KiSelect
								classNamePrefix="aut-select"
								value={{
									label: 'Data',
									value: 'data',
									type: 'data',
								}}
								isClearable={false}
								isSearchable={false}
								options={[
									{
										label: 'Data',
										value: 'data',
										type: 'data',
									},
								]}
							/>
						</div>
						<div className={styles.formSelectSection}>
							<span className="form-instruction">Tags:</span>
							<CreatableSelect
								isMulti={true}
								isClearable={true}
								options={existingTags.map(t => ({
									value: t,
									label: t,
								}))}
								value={tags.map(t => ({
									value: t,
									label: t,
								}))}
								onChange={val => (val ? setTags(val.map(t => t.value)) : setTags([]))}
								placeholder="Add some tags"
								noResultsText={false}
							/>
						</div>
					</div>
					<div className={styles.column}>{getCardSettingsColumn()}</div>
				</section>
			</div>
			<section className="inline-column-form-buttons">
				{mode === 'dialog' && (
					<div className={styles.deleteContainer}>
						<KiButton
							disabled={!(!isDeleteDisabled && deleteCard)}
							flat
							primary
							onClick={() => deleteCard()}
							label="Delete"
						/>
					</div>
				)}
				{baseCard.isSystem && (
					<div className={styles.systemCardMsg}>
						This is a migrated card and cannot have any settings or overrides applied to it
					</div>
				)}
				<KiButton flat primary onClick={() => close()} label="Cancel" />
				<KiButton
					raised
					primary
					disabled={!!nameError || isSaveDisabled}
					onClick={() => saveChanges()}
					label="Save"
				/>
			</section>
		</div>
	);
}

FundingAnalysisCardForm.propTypes = {
	baseCard: PropTypes.object.isRequired,
	existingTags: PropTypes.array.isRequired,
	mode: PropTypes.string.isRequired,
	onDialogClose: PropTypes.func.isRequired,
	saveCard: PropTypes.func.isRequired,
	deleteCard: PropTypes.func.isRequired,
	isDeleteDisabled: PropTypes.bool,
	isSaveDisabled: PropTypes.bool,
};

FundingAnalysisCardForm.defaultProps = {
	isDeleteDisabled: false,
	isSaveDisabled: false,
};

export default FundingAnalysisCardForm;
