import {
	dashboardsApi,
	datasetDatesApi,
	datasetsApi,
	debtExplorerApi,
	fundingAnalysisApi,
	fundingVehiclesApi,
	reportsApi,
	viewsApi,
} from 'api';
import {fetchScenarioList} from 'api/explorerApi';
import {debtScenarioContexts} from 'ki-common/options/debt';
import {get, isEmpty, isEqual, sortBy} from 'lodash';

export const getDatasets = async () => {
	return await datasetsApi.fetchDatasetList();
};

export const getReports = async () => {
	return await reportsApi.fetchReports();
};

export const getReport = async reportId => {
	const report = await reportsApi.fetchReportDefinition(reportId);
	if (!report && !report.settings) throw new Error('Report not found'); // no sense in proceeding
	return report;
};

export const getDisplayCards = async (report, statementDate, reportFundingVehicle) => {
	const {datasetId} = report.settings;
	const fundingVehicles = await fundingVehiclesApi.fetchFundingVehicleList(datasetId);
	const datasetCards = await dashboardsApi.fetchAllCards({datasetId: datasetId});
	const datasetDates = await datasetDatesApi.fetchDates(datasetId, true, true, true);
	const datasetViews = await viewsApi.fetchViewsByDataset(datasetId);
	const datasetDebtViews = await debtExplorerApi.fetchBookmarks(datasetId);
	const fundingAnalysisViews = await fundingAnalysisApi.fetchBookmarks(datasetId);
	const scenarios = await fundingAnalysisApi.fetchScenarios(datasetId);
	const scenarioOptions = scenarios.map(scenario => {
		return {
			value: scenario._id,
			label: scenario.name,
		};
	});

	const getCalculatedDateInfo = (
		dateContextId,
		dateContextList,
		statementDate,
		fundingVehicleId,
		isFixedDate,
		datasetId
	) => {
		const dateContext = dateContextList.find(d => d._id === dateContextId);
		const calculatedDatesRequest = [
			{
				groupId: dateContext.groupId,
				statementDate: statementDate,
				fundingVehicleId: fundingVehicleId,
				isFixedDate: isFixedDate,
			},
		];
		return datasetDatesApi.getCalculatedDates(calculatedDatesRequest, datasetId).then(info => {
			if (info.length) return info;
		});
	};

	const getCalculatedDebtDateInfo = (groupId, dateContextList, statementDate, fundingVehicleId, datasetId) => {
		const dateContext = dateContextList.find(d => d.groupId === groupId);
		const calculatedDatesRequest = [
			{
				groupId: dateContext ? dateContext.groupId : groupId,
				statementDate: statementDate,
				fundingVehicleId: fundingVehicleId,
			},
		];
		return datasetDatesApi.getCalculatedDates(calculatedDatesRequest, datasetId).then(info => {
			if (info.length) return info;
		});
	};

	const getFundingVehicleLabels = card => {
		if (card.settings.useReportFundingVehicle) {
			return 'Report Setting';
		} else if (isEqual(card.settings.fundingVehicleIds, ['all'])) {
			return 'All';
		} else {
			if (card.settings.fundingVehicleIds) {
				const matchedFVs = fundingVehicles.filter(fv => card.settings.fundingVehicleIds.includes(fv._id));
				return !isEmpty(matchedFVs) ? matchedFVs.map(fv => fv.name).join(',') : card.settings.fundingVehicleIds;
			}
			if (card.settings.fundingVehicle) {
				const match = fundingVehicles.find(fv => fv._id === card.settings.fundingVehicle);
				return match.name;
			}
			return 'Unconfigured';
		}
	};

	const getScenarioLabel = card => {
		const scenarioId = get(card, 'settings.scenarioId', '');
		const option = debtScenarioContexts.find(option => option.value === scenarioId);
		if (!option) return 'Report Setting';
		return option.label;
	};

	const getFAScenarioLabel = card => {
		if (card.settings.useReportScenarioId || !card.settings.scenarioType) {
			return 'Report Setting';
		}
		if (card.settings.tableType === 'summary') {
			const option = debtScenarioContexts.find(option => option.value === card.settings.scenarioType);
			return option.label;
		}
		if (card.settings.tableType === 'scenario' || card.settings.paymentReportSource === 'scenario') {
			const option = scenarioOptions.find(option => option.value === card.settings.scenarioId);
			return option?.label;
		}
	};

	const getPmtScenarioLabel = card => {
		if (card.settings.paymentReportSource === 'scenario') {
			const option = scenarioOptions.find(option => option.value === card.settings.scenarioId);
			return option?.label;
		} else {
			return 'Report Setting';
		}
	};

	return Promise.all(
		report.sections.map(async section => {
			const card = datasetCards.find(c => c._id === section._id);
			if (!card) {
				return {
					section: section,
					error: `Missing card (${section._id})`,
				};
			}

			let dateContext,
				assetDateContext,
				debtDateContext,
				fundingVehicle,
				view,
				cardTypeLabel,
				dateContextData,
				fundingVehicleLabels,
				scenarioLabel;
			switch (card.settings.type) {
				case 'fundingAnalysisView':
					cardTypeLabel = 'Funding Analysis';
					fundingVehicleLabels = getFundingVehicleLabels(card);
					scenarioLabel = getFAScenarioLabel(card);
					view = fundingAnalysisViews.find(v => v._id === card.settings.fundingAnalysisBookmarkId);

					// scenario type calls do not have a date context
					// NOTE - statementDate only required if calculating the new date
					// if (statementDate ) {
					if (['summary', 'eligibility'].includes(card.settings.tableType)) {
						try {
							dateContext = datasetDates.find(d => d._id === card.settings.dateContext);

							// NOTE right now they are not looking for the actual date
							dateContextData = {
								name: dateContext.name,
							};

							// const dateInfo = await datasetDatesApi.getCalculatedDate(
							// 	datasetId,
							// 	dateContext.groupId,
							// 	statementDate
							// );
							// dateContextData = {
							// 	name: dateInfo.name,
							// 	calculatedDate: dateInfo.calculatedDate,
							// };
						} catch (err) {
							dateContextData = {
								error: err.message,
							};
						}
					}
					break;
				case 'tabular':
					cardTypeLabel = 'Asset Exploration';
					fundingVehicleLabels = getFundingVehicleLabels(card);
					scenarioLabel = getScenarioLabel(card);

					fundingVehicle = fundingVehicles.find(fv => fv._id === card.settings.fundingVehicle);
					view = datasetViews.find(v => v._id === card.bookmarkId);
					if (!view) {
						return {
							section: section,
							error: `Missing view (${card.bookmarkId})`,
						};
					}
					dateContext = datasetDates.find(d => d._id === view.explorerData.dateContext);

					if (statementDate) {
						// edit view won't care about dates so do not calculate
						try {
							const dateData = await getCalculatedDateInfo(
								view.explorerData.dateContext,
								datasetDates,
								view.explorerData.isFixedDate ? view.explorerData.statementDate : statementDate,
								(fundingVehicle && fundingVehicle._id) || reportFundingVehicle,
								view.explorerData.isFixedDate,
								view.explorerData.datasetId
							);
							dateContextData = dateData[0];
						} catch (err) {
							dateContextData = {error: err.message};
						}
					} else {
						const dateContext = datasetDates.find(d => d._id === view.explorerData.dateContext);
						if (view.explorerData.isFixedDate) {
							dateContextData = {
								name: 'Static Date',
								calculatedDate: view.explorerData.statementDate,
							};
						} else if (dateContext) {
							dateContextData = {
								name: dateContext.name,
								calculatedDate: '',
							};
						} else {
							dateContextData = {error: 'No Context Found'};
						}
					}
					break;
				case 'debtView':
					cardTypeLabel = 'Debt Exploration';
					fundingVehicleLabels = getFundingVehicleLabels(card);
					scenarioLabel = getScenarioLabel(card);

					fundingVehicle = fundingVehicles.find(fv => fv._id === card.settings.fundingVehicle);
					view = datasetDebtViews.find(v => v._id === card.settings.debtBookmarkId);
					if (!view) {
						return {
							section: section,
							error: `Missing debt view (${card.settings.debtBookmarkId})`,
						};
					}
					// debt has two date contexts, which will either be a groupId or default
					assetDateContext = datasetDates.find(d => d.groupId == view.settings.assetDateContext);
					if (!assetDateContext) {
						assetDateContext = datasetDates.find(d => d.groupId == 'collectionEndDate'); //default
					}
					debtDateContext = datasetDates.find(d => d.groupId == view.settings.debtDateContext);
					if (!debtDateContext) {
						debtDateContext = datasetDates.find(d => d.groupId == 'reportDate'); //default
					}

					if (statementDate) {
						// edit view won't care about dates so do not calculate
						let calculatedDateAssetData, calculatedDateDebtData;
						try {
							calculatedDateAssetData = await getCalculatedDebtDateInfo(
								assetDateContext.groupId,
								datasetDates,
								statementDate,
								(fundingVehicle && fundingVehicle._id) || reportFundingVehicle,
								card.datasetId
							);
							calculatedDateAssetData = calculatedDateAssetData[0];
						} catch (err) {
							calculatedDateAssetData = {error: err.message};
						}
						try {
							calculatedDateDebtData = await getCalculatedDebtDateInfo(
								debtDateContext.groupId,
								datasetDates,
								statementDate,
								(fundingVehicle && fundingVehicle._id) || reportFundingVehicle,
								card.datasetId
							);
							calculatedDateDebtData = calculatedDateDebtData[0];
						} catch (err) {
							calculatedDateDebtData = {error: err.message};
						}
						dateContextData = {
							dateContextAssetData: calculatedDateAssetData,
							dateContextDebtData: calculatedDateDebtData,
						};
					}
					break;
				case 'projections':
					cardTypeLabel = 'Forecasting';
					break;
				case 'aggregatedPayment':
					cardTypeLabel = 'Payment Exploration';
					fundingVehicleLabels = getFundingVehicleLabels(card);
					scenarioLabel = getPmtScenarioLabel(card);
					break;
			}
			return {
				section: section,
				card: card,
				fundingVehicleLabels,
				scenarioLabel,
				dateContext: dateContext,
				assetDateContext: assetDateContext,
				debtDateContext: debtDateContext,
				view: view,
				cardTypeLabel: cardTypeLabel,
				dateContextData: dateContextData,
			};
		})
	);
};

export const getFundingVehicles = async (datasetId, statementDate) => {
	if (datasetId) {
		if (statementDate) {
			// display needs dates to show on the list
			return await fundingVehiclesApi.fetchFundingVehiclesWithDates(datasetId, statementDate);
		}
		// definition page doesn't have statement date
		return await fundingVehiclesApi.fetchFundingVehicleList(datasetId);
	}
	return await fundingVehiclesApi.fetchFundingVehicleList();
};

export const getScenarios = async (datasetId, fundingVehicleId, statementDate) => {
	const scenarioList = await fetchScenarioList(datasetId, fundingVehicleId, statementDate, 'lastApproved');
	return scenarioList ? scenarioList : [];
};

export const OutputType = Object.freeze({
	Json: 'json',
	Csv: 'csv',
	Pdf: 'pdf',
	Xlsx: 'xlsx',
});

export const getOutputTypes = () => {
	return [
		{value: OutputType.Json, label: 'JSON'},
		{value: OutputType.Csv, label: 'CSV File'},
		{value: OutputType.Pdf, label: 'PDF File'},
		{value: OutputType.Xlsx, label: 'XLSX File'},
	];
};

export const getReportGroups = async datasetId => {
	let reportGroups = [];
	if (datasetId) {
		reportGroups = await reportsApi.fetchReportGroupsByDatasetId(datasetId);
	} else {
		reportGroups = await reportsApi.fetchReportGroups();
	}
	const sortedReportGroups = sortBy(reportGroups, reportGroup => !reportGroup.isDefault);
	return sortedReportGroups;
};
