// Dependencies
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import _get from 'lodash/get';

// Project imports
import {debtScenarioContexts} from 'ki-common/options/debt';

// Website components
import {dashboardsApi, fundingVehiclesApi} from 'api';
import {showSnackbar} from 'state/actions/Snackbar';
import {fetchAllCards, setCurrentCard, deleteCard} from 'containers/dashboards/actions';

// Relative
import TabularCardForm from './TabularCardForm';
import DebtCardForm from './DebtCardForm';
import PaymentsCardForm from './PaymentsCardForm';
import FundingAnalysisCardForm from './FundingAnalysisCardForm';

export class CardSettings extends Component {
	static propTypes = {
		card: PropTypes.object,
		//existingTags: PropTypes.array.isRequired,
		closeModal: PropTypes.func,
		isDeleteDisabled: PropTypes.bool,
		isSaveDisabled: PropTypes.bool,
		wasUpdated: PropTypes.bool,
		setWasUpdated: PropTypes.func,

		// Redux Props
		allCards: PropTypes.array,
		currentCard: PropTypes.object,

		// Dispatch Props
		showSnackbar: PropTypes.func,
		fetchAllCards: PropTypes.func,
		setCurrentCard: PropTypes.func,
		deleteCard: PropTypes.func,
	};

	static defaultProps = {
		//existingTags: [],
		isDeleteDisabled: false,
		isSaveDisabled: false,
	};

	state = {
		fundingVehicles: [],
		existingTags: [],
	};

	componentDidMount() {
		fundingVehiclesApi.fetchFundingVehiclesByDatasetId(this.props.card.datasetId).then(fvs => {
			this.setState({fundingVehicles: fvs});
		});
		dashboardsApi.getAllCardTags(this.props.card.datasetId).then(allTags => {
			this.setState({existingTags: allTags});
		});
		this.setActionsForCard();
	}

	componentDidUpdate(prevProps) {
		if (this.props.card !== prevProps.card) {
			this.setActionsForCard();
		}
	}

	setActionsForCard = () => {
		if (this.props.card.isSystem) {
			this.setState({
				isDeleteDisabled: true,
				isSaveDisabled: true,
			});
		} else {
			this.setState({
				isDeleteDisabled: this.props.isDeleteDisabled || false,
				isSaveDisabled: this.props.isSaveDisabled || false,
			});
		}
	};

	saveCard = card => {
		if (this.props.isSaveDisabled) {
			return false;
		}
		const {fetchAllCards, setCurrentCard, showSnackbar, wasUpdated, setWasUpdated, closeModal} = this.props;
		setWasUpdated?.(!wasUpdated);
		closeModal();

		return dashboardsApi.addCard(card).then(() => {
			setCurrentCard(card);
			showSnackbar(`Saved card "${card.name}" successfully`);
			return fetchAllCards(card.datasetId);
		});
	};

	updateCurrentCard = card => {
		this.props.setCurrentCard(card);
		this.props.fetchAllCards(this.props.card.datasetId);
		this.props.showSnackbar('Card saved');
	};

	handleDelete = () => {
		const {isDeleteDisabled, closeModal, deleteCard, currentCard} = this.props;
		if (isDeleteDisabled) {
			closeModal();
			return false;
		}
		return deleteCard(currentCard).then(() => {
			closeModal();
		});
	};

	render() {
		const {card} = this.props;
		const {existingTags, isDeleteDisabled, isSaveDisabled} = this.state;

		if (!card && !this.state.fundingVehicles.length) {
			<div> Loading </div>;
		}

		const type = _get(card, 'settings.type');
		switch (type) {
			case 'tabular':
				return (
					<div className="context-sidebar-panel-flex column-selector-form">
						<TabularCardForm
							card={card}
							onDialogClose={this.props.closeModal}
							existingTags={existingTags}
							saveCard={this.saveCard}
							mode={'dialog'}
							deleteCard={this.handleDelete}
							setSavedStatus={() => ({})}
							fundingVehicles={this.state.fundingVehicles}
							allCards={this.props.allCards}
							scenarioList={debtScenarioContexts}
							isDeleteDisabled={isDeleteDisabled}
							isSaveDisabled={isSaveDisabled}
						/>
					</div>
				);
			case 'fundingAnalysisView':
				return (
					<div className="context-sidebar-panel-flex column-selector-form">
						<FundingAnalysisCardForm
							baseCard={card}
							existingTags={existingTags}
							mode={'dialog'}
							onDialogClose={this.props.closeModal}
							saveCard={this.saveCard}
							deleteCard={this.handleDelete}
							isDeleteDisabled={isDeleteDisabled}
							isSaveDisabled={isSaveDisabled}
						/>
					</div>
				);
			case 'debtView':
			case 'projections':
				return (
					<div className="context-sidebar-panel-flex column-selector-form">
						<DebtCardForm
							card={card}
							onDialogClose={this.props.closeModal}
							existingTags={existingTags}
							saveCard={this.saveCard}
							mode={'dialog'}
							deleteCard={this.handleDelete}
							setSavedStatus={() => ({})}
							fundingVehicles={this.state.fundingVehicles}
							allCards={this.props.allCards}
							scenarioList={debtScenarioContexts}
							isDeleteDisabled={isDeleteDisabled}
							isSaveDisabled={isSaveDisabled}
						/>
					</div>
				);
			case 'aggregatedPayment':
				return (
					<div className="context-sidebar-panel-flex column-selector-form">
						<PaymentsCardForm
							baseCard={card}
							existingTags={existingTags}
							mode={'dialog'}
							onDialogClose={this.props.closeModal}
							saveCard={this.saveCard}
							deleteCard={this.handleDelete}
							isDeleteDisabled={isDeleteDisabled}
							allCards={this.props.allCards}
							isSaveDisabled={isSaveDisabled}
						/>
					</div>
				);

			default:
				return <div>Loading Card Settings</div>;
		}
	}
}

const mapStateToProps = state => ({
	allCards: state.dashboards.allCards,
	currentCard: state.dashboards.currentCard,
});

const mapDispatchToProps = {
	setCurrentCard,
	deleteCard,
	showSnackbar,
	fetchAllCards,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CardSettings);
