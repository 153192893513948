// Global Imports
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import _ from 'lodash';

// Project Imports
import KiButton from 'components/KiButton';
import KiInput from 'components/KiInput';
import KiSelect from 'components/KiSelect';
import {fetchScenarios} from 'api/fundingAnalysisApi';
import {fetchFundingVehicleList} from 'api/fundingVehiclesApi';
import {useMergedState} from 'utils/customHooks';

// Relative Imports
import styles from './cardForm.theme.scss';
// import {checkNameValid} from '../KiDataCardEditorUtils';

//const scenarioContextOptions = [].concat(debtScenarioContexts);

function PaymentsCardForm({
	baseCard,
	existingTags,
	mode,
	onDialogClose,
	saveCard,
	deleteCard,
	isDeleteDisabled,
	isSaveDisabled,
	allCards,
}) {
	const [name, setName] = useState(baseCard.name);
	const [nameError, setNameError] = useState('');
	const [tags, setTags] = useState(baseCard.tags || []);
	const [cardSettings, setCardSetting] = useMergedState({...baseCard.settings});
	const [scenarioError, setScenarioError] = useState();
	const [fundingVehicleError, setFundingVehicleError] = useState();
	const [scenarioList, setScenarioList] = useState([]);
	const [selectedScenario, setSelectedScenario] = useState({});
	const [fundingVehicleList, setFundingVehicleList] = useState([]);

	// On load
	useEffect(
		() => {
			const fetchInitalLoadData = async () => {
				// Fetch all scenarios
				const scenarioData = await fetchScenarios(baseCard.datasetId);
				setScenarioList(
					scenarioData.map(scenario => {
						return {
							value: scenario._id,
							label: scenario.name,
							fvSettings: scenario.fvSettings,
						};
					})
				);

				if (baseCard.settings.scenarioId) {
					setSelectedScenario(scenarioData.find(s => s._id === baseCard.settings.scenarioId));
				}

				/*
			if (cardSettings.paymentReportSource === 'scenario' && !cardSettings.scenarioId) {
				setCardSetting({scenarioId: scenarioData[0]._id});
				setSelectedScenario(scenarioData[0])
			}
			*/
				// Fetch date context options
				/*
			const dates = await fetchPortfolioDates(baseCard.datasetId);
			setDateContextList(dates.filter(d => d.readOnly));
			*/

				// Fetch funding models and filter down to name and ID
				const fvs = await fetchFundingVehicleList(baseCard.datasetId);
				const fvOptions = fvs.map(fv => {
					return {
						fvName: fv.name,
						fvId: fv._id,
					};
				});
				setFundingVehicleList(fvOptions);
			};
			fetchInitalLoadData();
		},
		[baseCard]
	);

	const checkNameValid = (name, id) => {
		const match = allCards.find(c => c.name.trim() === name.trim() && c._id !== id);
		const isEmpty = name.trim().length === 0 ? true : false;
		if (match) {
			setNameError('Name already in use');
		} else if (isEmpty) {
			setNameError('Name cannot be blank');
		} else {
			setNameError('');
		}
	};

	const saveChanges = () => {
		let fvHasError = false,
			scHasError = false;
		// Verify scenario for scenario types
		if (cardSettings.paymentReportSource === 'scenario') {
			if (_.isEmpty(cardSettings.scenarioId)) {
				setScenarioError('A scenario is required');
				scHasError = true;
			} else {
				setScenarioError('');
			}
			//to do: save fvId of scenario here so we can query?
		}

		// Verify funding vehicle
		if (_.isEmpty(cardSettings.fundingVehicle)) {
			setFundingVehicleError('A funding vehicle is required');
			fvHasError = true;
		} else {
			setFundingVehicleError('');
		}

		if (name.trim().length === 0) {
			setNameError('Name cannot be blank');
			return null;
		}

		if (fvHasError || scHasError || nameError !== '') {
			return null;
		}
		const newCard = Object.assign({}, baseCard, {name}, {tags});
		newCard.settings = cardSettings;
		saveCard(newCard);
	};

	const close = () => {
		if (mode === 'dialog') {
			onDialogClose();
		}
	};

	const getFundingVehicleSection = () => {
		// if its a scenario card, limit the fvs to those targets in the scenario
		const validFvs =
			cardSettings.paymentReportSource === 'scenario' && selectedScenario.fvSettings
				? fundingVehicleList.filter(fv =>
						selectedScenario.fvSettings
							.filter(f => f.fvType === 'target')
							.map(f => f.fvId)
							.includes(fv.fvId)
				  )
				: fundingVehicleList;

		return (
			<React.Fragment>
				<div className={styles.formSelectSection}>
					<span className="form-instruction">Funding Vehicle</span>
					<KiSelect
						classNamePrefix="aut-select"
						closeOnSelect={true}
						isClearable={false}
						isMulti={false}
						onChange={fv => {
							setCardSetting({fundingVehicle: fv.fvId});
							setFundingVehicleError();
						}}
						options={validFvs}
						value={validFvs.filter(option => cardSettings.fundingVehicle === option.fvId)}
						getOptionLabel={option => option.fvName}
						getOptionValue={option => option.fvId}
						errorMessage={fundingVehicleError}
					/>
				</div>
			</React.Fragment>
		);
	};

	const getCardSettingsColumn = () => {
		return (
			<React.Fragment>
				{cardSettings.paymentReportSource === 'scenario' && (
					<React.Fragment>
						<div className={styles.formSelectSection}>
							<span className="form-instruction">Scenario</span>
							<KiSelect
								classNamePrefix="aut-select"
								closeOnSelect={true}
								isClearable={false}
								options={scenarioList}
								value={scenarioList.find(option => option.value === cardSettings.scenarioId)}
								getOptionLabel={option => option.label}
								getOptionValue={option => option.value}
								onChange={selected => {
									setCardSetting({scenarioId: selected.value});
									setSelectedScenario(selected);

									//reset fv to first valid
									const validFvs = fundingVehicleList.filter(fv =>
										selected.targetFvs?.includes(fv.fvId)
									);
									if (Array.isArray(validFvs) && validFvs.length > 0) {
										setCardSetting({fundingVehicle: validFvs[0].fvId});
									}

									setScenarioError();
								}}
								errorMessage={scenarioError}
							/>
						</div>
					</React.Fragment>
				)}
				{getFundingVehicleSection()}
			</React.Fragment>
		);
	};

	return (
		<div className="context-sidebar-panel-flex column-selector-form">
			<div>
				{!mode === 'dialog' && <header className="flyout-panel-title">MANAGE CARDS</header>}
				<section className={mode === 'dialog' ? styles.cardFormDialog : ''}>
					<div className={styles.column}>
						<div className={styles.formInputSection}>
							<KiInput
								label="Name"
								value={name}
								onChange={val => {
									setName(val);
									checkNameValid(val, baseCard._id);
								}}
								error={nameError}
							/>
						</div>
						<div className={styles.formSelectSection}>
							<span className="form-instruction">Display Type:</span>
							<KiSelect
								classNamePrefix="aut-select"
								value={{
									label: 'Data',
									value: 'data',
									type: 'data',
								}}
								isClearable={false}
								isSearchable={false}
								options={[
									{
										label: 'Data',
										value: 'data',
										type: 'data',
									},
								]}
							/>
						</div>
						<div className={styles.formSelectSection}>
							<span className="form-instruction">Tags:</span>
							<CreatableSelect
								isMulti={true}
								isClearable={true}
								options={existingTags.map(t => ({
									value: t,
									label: t,
								}))}
								value={tags.map(t => ({
									value: t,
									label: t,
								}))}
								onChange={val => (val ? setTags(val.map(t => t.value)) : setTags([]))}
								placeholder="Add some tags"
								noResultsText={false}
							/>
						</div>
					</div>
					<div className={styles.column}>{getCardSettingsColumn()}</div>
				</section>
			</div>
			<section className="inline-column-form-buttons">
				{mode === 'dialog' && (
					<div className={styles.deleteContainer}>
						<KiButton
							disabled={!isDeleteDisabled && deleteCard ? false : true}
							flat
							primary
							onClick={() => deleteCard()}
							label="Delete"
						/>
					</div>
				)}
				{baseCard.isSystem && (
					<div className={styles.systemCardMsg}>
						This is a migrated card and cannot have any settings or overrides applied to it
					</div>
				)}
				<KiButton flat primary onClick={() => close()} label="Cancel" />
				<KiButton
					raised
					primary
					disabled={!!nameError || isSaveDisabled}
					onClick={() => saveChanges()}
					label="Save"
				/>
			</section>
		</div>
	);
}

PaymentsCardForm.propTypes = {
	baseCard: PropTypes.object.isRequired,
	existingTags: PropTypes.array.isRequired,
	allCards: PropTypes.array,
	mode: PropTypes.string.isRequired,
	onDialogClose: PropTypes.func.isRequired,
	saveCard: PropTypes.func.isRequired,
	deleteCard: PropTypes.func.isRequired,
	isDeleteDisabled: PropTypes.bool,
	isSaveDisabled: PropTypes.bool,
};

PaymentsCardForm.defaultProps = {
	isDeleteDisabled: false,
	isSaveDisabled: false,
};

export default PaymentsCardForm;
