/* eslint-disable react/prop-types */
// Global imports
import PropTypes from 'prop-types';
import React, {useState, useRef} from 'react';
import {components} from 'react-select';

// Project imports
import KiFontIcon from 'components/KiFontIcon';
import KiButton from 'components/KiButton';
import KiSelect from 'components/KiSelect';

// Local imports
import NewReportGroupModal from './NewReportGroupModal';
import styles from './ReportGroups.theme.scss';

const ReportGroups = ({datasets, reportGroups, activeReportGroupId, onChangeReportGroupId, onSaveReportGroup}) => {
	const [isModalShown, setIsModalShown] = useState(false);
	const selectElement = useRef(null);
	const reportGroupOptions = reportGroups.map(reportGroup => ({
		label: reportGroup.name,
		value: reportGroup._id,
		isDefault: reportGroup.isDefault,
	}));
	const {Option, SingleValue, Menu} = components;

	const CustomOption = props => (
		<Option {...props} className={styles.customOption}>
			{props.data.isDefault ? <KiFontIcon value="home" className={styles.icon} /> : null}
			{props.data.label}
		</Option>
	);

	const CustomSingleValue = props => (
		<SingleValue {...props} className={styles.customSingleValue}>
			{props.data.isDefault ? <KiFontIcon value="home" className={styles.icon} /> : null}
			{reportGroups.length ? props.data.label : ''}
		</SingleValue>
	);

	const CustomMenu = props => (
		<Menu {...props} className={styles.customMenu}>
			{props.children}
			<div className={styles.footer}>
				<KiButton
					raised
					primary
					onClick={() => {
						setIsModalShown(true);
					}}
				>
					New Report Group
				</KiButton>
			</div>
		</Menu>
	);

	return (
		<div className={styles.root}>
			<span className="theme-label">Report Group</span>
			<KiSelect
				selectRef={selectElement}
				options={reportGroupOptions}
				value={reportGroupOptions.find(option => option.value === activeReportGroupId)}
				onChange={option => {
					onChangeReportGroupId(option.value);
				}}
				components={{Option: CustomOption, SingleValue: CustomSingleValue, Menu: CustomMenu}}
			/>

			{isModalShown ? (
				<NewReportGroupModal
					datasets={datasets}
					onSave={async () => {
						await onSaveReportGroup();
						setIsModalShown(false);
						selectElement.current.focus();
					}}
					onCancel={() => {
						setIsModalShown(false);
						selectElement.current.focus();
					}}
				/>
			) : null}
		</div>
	);
};

ReportGroups.propTypes = {
	datasets: PropTypes.array.isRequired,
	reportGroups: PropTypes.array.isRequired,
	activeReportGroupId: PropTypes.string,
	onChangeReportGroupId: PropTypes.func.isRequired,
	onSaveReportGroup: PropTypes.func.isRequired,
};

export default ReportGroups;
