import React, {Component} from 'react';
import PropTypes from 'prop-types';
// react imports
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import _omit from 'lodash/omit';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import _ from 'lodash';
import moment from 'moment';
// component imports
import KiAppBar from 'components/KiAppBar';
import KiTabs from 'components/KiTabs';
import KiTab from 'components/KiTabs/KiTab';
import KiOverlay from 'components/KiOverlay';
import KiFontIcon from 'components/KiFontIcon';
import KiDatePicker from 'components/KiDatePicker';
import KiButtonSpinner from 'components/KiButtonSpinner';
import DebtBreadcrumb from './DebtBreadcrumb';
import EditView from 'components/EditView';
import {KiIconCsv} from 'components/KiIcons';
import CircularProgress from '@material-ui/core/CircularProgress';

// action imports
import {setFundingVehicle, saveBookmark} from '../actions';
import {persistenceApi} from 'api';
import {_getHeaders, apiUrl} from 'api/apiUtils';
import {showSnackbar} from 'state/actions/Snackbar';
// style imports
import styles from '../debt.theme.scss';
import explorerStyles from 'containers/dataExplorer/dataExplorer.theme.scss';

import {dateToShortDate} from 'ki-common/utils/dateHelpers';

export class DebtHeader extends Component {
	static propTypes = {
		match: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired,
		user: PropTypes.object,
		dataset: PropTypes.object,
		view: PropTypes.object,
		appliedViewFilters: PropTypes.object,
		setFundingVehicle: PropTypes.func,
		fundingVehicleList: PropTypes.array,
		saveBookmark: PropTypes.func,
		showSnackbar: PropTypes.func,
	};

	static defaultProps = {
		fundingVehicleList: [],
	};

	state = {
		date: null,
		inputDate: null,
		tabIndex: 0,
		saveDialogOpen: false,
		poolPersistenceJobs: [],
		loadingCSVIcon: false,
	};

	componentDidMount() {
		this.refreshPersistence(true);
		this.refreshPersistenceInterval = setInterval(() => {
			this.refreshPersistence(false);
		}, 10000);
	}

	componentWillUnmount() {
		clearInterval(this.refreshPersistenceInterval);
	}

	toggleLoadingCSVIcon = () => {
		this.setState({loadingCSVIcon: !this.state.loadingCSVIcon});
	};

	toggleSaveDialog = () => {
		this.setState({
			saveDialogOpen: !this.state.saveDialogOpen,
			poolPersistenceStatusOpen: false,
		});
	};

	handleSaveView = view => {
		this.props.saveBookmark(view).then(() => this.toggleSaveDialog());
	};

	handleCopyView = view => {
		this.props.saveBookmark(_omit(view, '_id')).then(result => {
			this.toggleSaveDialog();
			this.props.history.push({search: `?bookmarkId=${result._id}`});
		});
	};

	getManualRunDate = jobs => {
		let manualJobs = _cloneDeep(jobs.filter(j => j.type !== 'input'));
		manualJobs = manualJobs.filter(job => {
			if (job.reason === 'submission' && ['failed', 'queued'].includes(job.status)) {
				return !moment(job.snapshotDate).isBefore(dateToShortDate(new Date()));
			}
			return ['failed', 'queued'].includes(job.status) && ['pool', 'scenario'].includes(job.reason);
		});
		manualJobs = _.sortBy(manualJobs, 'snapshotDate');
		return manualJobs.length ? manualJobs[0].snapshotDate : this.state.date;
	};

	getManualInputRunDate = jobs => {
		let manualJobs = _cloneDeep(jobs.filter(j => j.type === 'input'));
		manualJobs = manualJobs.filter(job => {
			if (job.reason === 'submission' && ['failed', 'queued'].includes(job.status)) {
				return !moment(job.snapshotDate).isBefore(dateToShortDate(new Date()));
			}
			return ['failed', 'queued'].includes(job.status) && ['pool', 'scenario'].includes(job.reason);
		});
		manualJobs = _.sortBy(manualJobs, 'snapshotDate');
		return manualJobs.length ? manualJobs[0].snapshotDate : this.state.inputDate;
	};

	showPoolPersistenceStatus = jobs => {
		// const jobsRunning = jobs.some(j => j.status === 'running');
		// const canJobRun = job => !jobsRunning && ['failed', 'queued'].includes(job.status);
		const poolJobs = jobs.filter(j => j.type !== 'input');
		const inputJobs = jobs.filter(j => j.type === 'input');
		const isAdmin = this.props.user.groups.findIndex(g => g === 'SystemAdmins') >= 0;
		const canJobBeDismissed = job => {
			return (
				isAdmin &&
				['failed', 'queued'].includes(job.status) &&
				['submission', 'column', 'pool', 'data'].includes(job.reason) &&
				moment(job.snapshotDate).isBefore(dateToShortDate(new Date()))
			);
		};
		const dismissJob = job => {
			this.setState({persistenceLoading: true});
			persistenceApi.updateJob({...job, status: 'dismissed'}).then(() => this.refreshPersistence());
		};
		return (
			<KiTabs onChange={idx => this.setState({tabIndex: idx})} index={this.state.tabIndex}>
				<KiTab title="Pool">
					<div className={styles.persistenceOverlay}>
						<table className={styles.jobsTable}>
							<thead>
								<tr>
									<th>Type</th>
									<th>Snapshot</th>
									<th>Status</th>
									<th>Dismiss</th>
								</tr>
							</thead>
							<tbody>
								{poolJobs.slice(0, 20).map(job => (
									<tr key={job._id} className={job.status === 'done' ? styles.jobsTableRowDone : ''}>
										<td>{job.reason}</td>
										<td>{job.snapshotDate}</td>
										<td>{job.status}</td>
										{canJobBeDismissed(job) && (
											<td>
												<KiFontIcon
													title="Dismiss"
													style={{paddingLeft: '1rem'}}
													value="close"
													onClick={() => dismissJob(job)}
												/>
											</td>
										)}
									</tr>
								))}
							</tbody>
						</table>
						{isAdmin && (
							<div className={styles.runPersistenceJob}>
								<span>Sync Date:</span>
								<KiDatePicker
									onChange={val => this.setState({date: val})}
									value={this.state.date ? this.state.date : null}
								/>
							</div>
						)}
					</div>
				</KiTab>
				<KiTab title="Debt Input">
					<div className={styles.persistenceOverlay}>
						<table className={styles.jobsTable}>
							<thead>
								<tr>
									<th>Type</th>
									<th>Snapshot</th>
									<th>Status</th>
									<th>Dismiss</th>
								</tr>
							</thead>
							<tbody>
								{inputJobs.slice(0, 20).map(job => (
									<tr key={job._id} className={job.status === 'done' ? styles.jobsTableRowDone : ''}>
										<td>{job.reason}</td>
										<td>{job.snapshotDate}</td>
										<td>{job.status}</td>
										{canJobBeDismissed(job) && (
											<td>
												<KiFontIcon
													title="Dismiss"
													style={{paddingLeft: '1rem'}}
													value="close"
													onClick={() => dismissJob(job)}
												/>
											</td>
										)}
									</tr>
								))}
							</tbody>
						</table>
						{isAdmin && (
							<div className={styles.runPersistenceJob}>
								<span>Sync Date:</span>
								<KiDatePicker
									onChange={val => this.setState({inputDate: val})}
									value={this.state.inputDate ? this.state.inputDate : null}
								/>
							</div>
						)}
					</div>
				</KiTab>
			</KiTabs>
		);
	};

	runManualPoolPersistenceJob = () => {
		const {date, inputDate, tabIndex, poolPersistenceJobs} = this.state;
		if (!date && tabIndex === 0) {
			this.props.showSnackbar('No pool sync date found.');
			return;
		}
		if (!inputDate && tabIndex === 1) {
			this.props.showSnackbar('No input sync date found.');
			return;
		}
		const targetJobs = poolPersistenceJobs.filter(j => (tabIndex === 0 ? j.type !== 'input' : j.type === 'input'));
		const targetDate = tabIndex === 0 ? date : inputDate;
		const jobToRun = targetJobs.find(
			j =>
				['failed', 'queued'].includes(j.status) &&
				[targetDate, moment(targetDate).format('YYYY-MM-DD')].includes(j.snapshotDate)
		);
		if (!jobToRun) {
			const sortedSnapshots = _.sortBy(_cloneDeep(this.props.dataset.snapshots), 'snapshotDate');
			const jobSnapshotDate = sortedSnapshots.find(sn =>
				moment(sn.snapshotDate).isSameOrAfter(moment(targetDate))
			).snapshotDate;
			if (tabIndex === 0) {
				return persistenceApi
					.createPersistenceJob(this.props.dataset.datasetId, jobSnapshotDate, 'column', 'pool')
					.finally(() => this.refreshPersistence());
			} else {
				return persistenceApi
					.createPersistenceJob(this.props.dataset.datasetId, jobSnapshotDate, 'column', 'input')
					.finally(() => this.refreshPersistence());
			}
		}
		this.setState({persistenceLoading: true});
		persistenceApi.runJob(jobToRun).then(() => this.refreshPersistence());
	};

	refreshPersistence = (updateDates = true) => {
		const datasetId = _get(this.props, 'match.params.datasetId');
		if (datasetId) {
			this.setState({persistenceLoading: true});
			persistenceApi
				.getPersistenceJobs(datasetId)
				.then((poolPersistenceJobs = []) => {
					const sortedJobs = _.sortBy(poolPersistenceJobs, [job => job.status !== 'done', 'date']).reverse();
					this.setState(state => ({
						poolPersistenceJobs: sortedJobs,
						persistenceLoading: false,
						date: updateDates ? this.getManualRunDate(sortedJobs) : state.date,
						inputDate: updateDates ? this.getManualInputRunDate(sortedJobs) : state.inputDate,
					}));
				})
				.catch(() => {
					this.setState({persistenceLoading: false});
				});
		}
	};

	onExportCurrentView = () => {
		const tempBookmark = _.cloneDeep(this.props.appliedViewFilters);
		delete tempBookmark._id;
		tempBookmark.isDefault = false;
		tempBookmark.isFavorite = false;
		tempBookmark.isReportOnly = true;

		// TODO may need to add
		// const start_date = _get(settings, 'start_date', '');
		// const end_date = _get(settings, 'end_date', '');

		const webFilename = encodeURIComponent(tempBookmark.name);
		const statementDate = _get(tempBookmark, 'settings.snapshotDate', '');
		this.toggleLoadingCSVIcon();

		return fetch(`${apiUrl}/debtBookmarks/exportCSV?filename=${webFilename}&statementDate=${statementDate}`, {
			credentials: 'include',
			method: 'POST',
			headers: _getHeaders('POST'),
			body: JSON.stringify(tempBookmark),
		})
			.then(res => {
				return res.blob();
			})
			.then(data => {
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(data);
				link.download = `${tempBookmark.name}_${new Date().getTime()}.csv`;
				link.click();
				this.toggleLoadingCSVIcon();
			});
	};

	render() {
		const {
			match: {params = {}},
			user,
			dataset,
			fundingVehicleList,
			view,
			view: {settings},
			appliedViewFilters,
			setFundingVehicle,
		} = this.props;

		const isAdmin = user.groups.findIndex(g => g === 'SystemAdmins') >= 0;

		return (
			<header>
				<KiAppBar className="top-bar">
					<div className={styles.topBarBreadcrumb}>
						<Link to={'/datasets'}>Datasets</Link>
						<Link to={`/dataExplorer/${params.datasetId}`}>{dataset.name}</Link>
						<Link to={`/datasets/${params.datasetId}/debt`} replace onClick={() => setFundingVehicle(null)}>
							Debt
						</Link>
						<DebtBreadcrumb
							type="fundingVehicle"
							options={fundingVehicleList}
							value={settings.fundingVehicle}
						/>
						<span onClick={() => null}>{view.name}</span>
					</div>
					<div className={styles.navActions}>
						<div
							className={`material-icons ${styles.csvLink}`}
							alt="Download CSV"
							title="Download CSV"
							onClick={() => this.onExportCurrentView()}
						>
							{this.state.loadingCSVIcon ? (
								<CircularProgress size={24} className={styles.csvProgress} />
							) : (
								<KiIconCsv />
							)}
						</div>
						<i
							disabled={!isAdmin}
							title="Pool Persistence Status"
							onClick={() =>
								this.setState(
									state => ({
										saveDialogOpen: false,
										poolPersistenceStatusOpen: !state.poolPersistenceStatusOpen,
										runManualPoolPersistenceOpen: false,
									}),
									this.refreshPersistence
								)
							}
							className={`material-icons ${
								this.state.poolPersistenceJobs.find(j => ['failed', 'queued'].includes(j.status))
									? explorerStyles.explorerIconActive
									: explorerStyles.explorerIcon
							}`}
						>
							sync
						</i>
						<i
							className={`material-icons ${explorerStyles.explorerIcon}`}
							title="Click to save the view."
							onClick={this.toggleSaveDialog}
						>
							save
						</i>
						{!!this.state.poolPersistenceStatusOpen && (
							<div
								style={{width: '65rem', borderBottom: '1px solid #ccc'}}
								className={`${styles.saveDialog} testing`}
							>
								<KiOverlay
									title={
										<div style={{display: 'flex', flexDirection: 'row'}}>
											<div>Persistence Jobs</div>
											<div>
												<KiButtonSpinner
													className={styles.peristenceJobSpinner}
													showSpinner={this.state.persistenceLoading}
													showButton={false}
													primary={false}
												/>
											</div>
										</div>
									}
									buttonTitle={isAdmin ? 'RUN' : 'OK'}
									buttonDisabled={this.state.poolPersistenceJobs.find(j => j.status === 'running')}
									onCancel={() => this.setState({poolPersistenceStatusOpen: false})}
									onOk={() =>
										isAdmin
											? this.runManualPoolPersistenceJob()
											: this.setState({poolPersistenceStatusOpen: false})
									}
									content={this.showPoolPersistenceStatus(this.state.poolPersistenceJobs)}
									className={styles.persistenceOverlay1}
								/>
							</div>
						)}
						{!!this.state.saveDialogOpen && (
							<div className={`${styles.saveDialog} testing`}>
								<EditView
									view={appliedViewFilters}
									save={this.handleSaveView}
									copy={this.handleCopyView}
									cancel={this.toggleSaveDialog}
									intitialName={!view.isGlobal && !isAdmin ? `${view.name} copy` : `${view.name}`}
									userBookmarks={[]}
									userId={user.userId}
									isCopy={!view.isGlobal && !isAdmin}
									isAdmin={isAdmin}
								/>
							</div>
						)}
					</div>
				</KiAppBar>
			</header>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user,
	dataset: state.datasetList.selected,
	view: state.debtExploration.view,
	appliedViewFilters: state.debtExploration.appliedViewFilters,
});

export default connect(
	mapStateToProps,
	{saveBookmark, setFundingVehicle, showSnackbar}
)(DebtHeader);
