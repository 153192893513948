/* eslint-disable no-unused-vars */
// Global imports
import cn from 'classnames';
import {cloneDeep, set, values} from 'lodash';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// Project imports
import {reportsApi} from 'api';
import KiCheckbox from 'components/KiCheckbox';
import KiDatePicker from 'components/KiDatePicker';
import KiModal2 from 'components/KiModal2';
import KiSelect from 'components/KiSelect';
import {showSnackbar} from 'state/actions/Snackbar';
import {useMergedState} from '../../../utils/customHooks';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';

// Local imports
import {getFundingVehicles, getOutputTypes, getScenarios} from '../helpers';
import styles from './ReportExecutionModal.theme.scss';

const outputTypes = getOutputTypes();

const VALIDATION_MESSAGES = {
	STATEMENT_DATE: 'Statement Date is required',
	SCENARIO_CONTEXT: 'Scenario Context is required',
	OUTPUT_TYPE: 'Output Type is required',
};

const ReportExecutionModal = ({reportDefinition, reportGroup, onExecute, onCancel, onChangeReportGroup}) => {
	const dispatch = useDispatch();
	const user = useSelector(state => state.user);
	const [formData, mergeFormData, setFormData] = useMergedState({
		saveAsDefaults: false,
		useAllReportIndividualParameters: false,
		parameters: {
			statementDate: new Date(),
			fundingVehicleId: null,
			scenarioContext: null,
			outputType: outputTypes[1].value,
		},
	});
	const [validationErrors, mergeValidationErrors, setValidationErrors] = useMergedState({
		parameters: {
			statementDate: null,
			scenarioContext: null,
			outputType: null,
		},
	});
	const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(true);
	const [fundingVehicles, setFundingVehicles] = useState([]);
	const [scenarios, setScenarios] = useState([]);
	const [isFetched, setIsFetched] = useState(false);

	const flattedScenarios = scenarios.flatMap(scenario => {
		if (scenario.options) {
			return scenario.options.map(option => option);
		}
		return scenario;
	});

	useEffect(() => {
		(async () => {
			const fundingVehicles = await getFundingVehicles(
				reportDefinition?.settings.datasetId || reportGroup?.datasetId
			);
			fundingVehicles.unshift({
				name: 'All',
				_id: null,
			});
			setFundingVehicles(fundingVehicles);

			if (reportDefinition) {
				mergeFormData({
					parameters: {
						fundingVehicleId: reportDefinition.settings.fundingVehicleId,
						scenarioContext: reportDefinition.settings.scenarioType,
						outputType: reportDefinition.settings.outputType,
					},
				});
			}

			if (reportGroup) {
				mergeFormData({
					parameters: {
						...(reportGroup.parameters?.scenarioContext && {
							scenarioContext: reportGroup.parameters.scenarioContext,
						}),
						...(reportGroup.parameters?.fundingVehicleId && {
							fundingVehicleId: reportGroup.parameters.fundingVehicleId,
						}),
						...(reportGroup.parameters?.outputType && {outputType: reportGroup.parameters.outputType}),
					},
				});
			}

			setIsFetched(true);
		})();
	}, []);

	const validateRequired = (fieldValue, fieldKey, message) => {
		const newValidationErrors = cloneDeep(validationErrors);

		if (fieldValue) {
			set(newValidationErrors, fieldKey, null);
		} else {
			set(newValidationErrors, fieldKey, message);
		}

		setValidationErrors(newValidationErrors);
	};

	const handleStatementDateChange = statementDate => {
		validateRequired(statementDate, 'parameters.statementDate', VALIDATION_MESSAGES.STATEMENT_DATE);
		mergeFormData({
			parameters: {
				statementDate,
			},
		});
	};

	useEffect(
		() => {
			(async () => {
				setScenarios([]);
				const statementDate = new Date(formData.parameters.statementDate.getTime());
				statementDate.setDate(statementDate.getDate() - 1);
				const rawScenarios = await getScenarios(
					reportDefinition?.settings.datasetId || reportGroup?.datasetId,
					formData.parameters.fundingVehicleId,
					dateToShortDate(statementDate)
				);

				if (rawScenarios.length > 0 && (isFetched || formData.parameters.scenarioContext)) {
					mergeFormData({
						parameters: {
							scenarioContext: rawScenarios[1].value,
						},
					});
				}

				setScenarios(rawScenarios);
			})();
		},
		[formData.parameters.statementDate]
	);

	const handleScenarioContextChange = scenarioContext => {
		validateRequired(scenarioContext, 'parameters.scenarioContext', VALIDATION_MESSAGES.SCENARIO_CONTEXT);
		mergeFormData({
			parameters: {
				scenarioContext,
			},
		});
	};

	const handleUseAllReportIndividualParametersChange = useAllReportIndividualParameters => {
		mergeFormData({
			useAllReportIndividualParameters,
		});
	};

	const handleFundingVehicleIdChange = fundingVehicleId => {
		mergeFormData({
			parameters: {
				fundingVehicleId,
			},
		});
	};

	const handleOutputTypeChange = outputType => {
		validateRequired(outputType, 'parameters.outputType', VALIDATION_MESSAGES.OUTPUT_TYPE);
		mergeFormData({
			parameters: {
				outputType,
			},
		});
	};

	const handleSaveAsDefaultsChange = saveAsDefaults => {
		mergeFormData({
			saveAsDefaults,
		});
	};

	const isFormValid = Object.keys(validationErrors)
		.map(key => {
			const error = validationErrors[key];
			if (error === null) {
				return true;
			} else if (typeof error === 'object') {
				return !values(error).filter(value => value !== null).length;
			}
			return false;
		})
		.reduce((previousValue, currentValue) => previousValue && currentValue);

	const handleSubmit = async () => {
		if (isFormValid) {
			try {
				setIsSaveButtonEnabled(false);

				if (reportDefinition) {
					if (formData.saveAsDefaults) {
						await reportsApi.updateReportDefinition({
							...reportDefinition,
							createdBy: reportDefinition.createdBy || user.userId,
							updatedBy: user.userId,
							settings: {
								...reportDefinition.settings,
								fundingVehicleId: formData.parameters.fundingVehicleId,
								scenarioType: formData.parameters.scenarioContext,
								outputType: formData.parameters.outputType,
							},
						});
					}

					const newIndividualReport = {
						datasetId: reportDefinition.settings.datasetId,
						reportDefinitionId: reportDefinition._id,
						parameters: {
							...formData.parameters,
						},
					};

					await reportsApi.createIndividualReport(newIndividualReport);

					dispatch(showSnackbar(`Report has been queued for execution`));
				}

				if (reportGroup) {
					if (formData.saveAsDefaults) {
						await reportsApi.updateReportGroup({
							...reportGroup,
							parameters: {
								scenarioContext: formData.parameters.scenarioContext,
								fundingVehicleId: formData.parameters.fundingVehicleId,
								outputType: formData.parameters.outputType,
							},
						});
						if (onChangeReportGroup) await onChangeReportGroup();
					}

					const newIndividualReport = {
						parameters: {
							statementDate: formData.parameters.statementDate,
						},
					};

					if (!formData.useAllReportIndividualParameters) {
						newIndividualReport.parameters.scenarioContext = formData.parameters.scenarioContext;
						newIndividualReport.parameters.outputType = formData.parameters.outputType;
						newIndividualReport.parameters.fundingVehicleId = formData.parameters.fundingVehicleId;
					}

					await reportsApi.createIndividualReportsByReportGroupId(reportGroup._id, newIndividualReport);
					dispatch(showSnackbar(`Report Group has been queued for execution`));
				}

				setIsSaveButtonEnabled(true);
				await onExecute();
			} catch (error) {
				setIsSaveButtonEnabled(true);
				const entity = reportGroup ? 'report group' : 'report';
				dispatch(showSnackbar(`Unable to submit a ${entity} for execution`));
			}
		}
	};

	if (!reportDefinition && !reportGroup) {
		return null;
	}

	const getModalTitle = () => {
		if (reportDefinition) {
			return `${reportDefinition.name} - Report Parameters`;
		}

		if (reportGroup) {
			return `${reportGroup.name} - Report Group Parameters`;
		}

		return '';
	};

	return (
		<KiModal2
			active={true}
			header={getModalTitle()}
			onClose={onCancel}
			actions={[
				{
					label: 'Cancel',
					onClick: () => {
						onCancel();
					},
				},
				{
					label: 'Execute',
					disabled: !(isFormValid && isSaveButtonEnabled),
					onClick: handleSubmit,
				},
			]}
		>
			<form id="reportExecution">
				<div className={styles.formGroup}>
					<KiDatePicker
						label="Statement Date"
						onChange={value => handleStatementDateChange(value)}
						value={formData.parameters.statementDate}
						className={styles.datePicker}
						labelClassName={styles.label}
						error={validationErrors.parameters.statementDate}
					/>
				</div>

				{reportGroup ? (
					<div className={styles.formGroup}>
						<KiCheckbox
							name="useAllReportIndividualParameters"
							checked={formData.useAllReportIndividualParameters}
							label="Use All Report Individual Parameters"
							onChange={value => handleUseAllReportIndividualParametersChange(value)}
							className={styles.checkbox}
							labelClassName={styles.label}
						/>
					</div>
				) : null}

				<div className={styles.formGroup}>
					<div className={cn(styles.selectWrapper)}>
						<label className={cn('theme-label', styles.label)}>Scenario Context</label>
						<KiSelect
							classNamePrefix="aut-select"
							closeOnSelect={true}
							isClearable={false}
							onChange={value => handleScenarioContextChange(value.value)}
							options={scenarios}
							value={
								flattedScenarios.find(item => item.value === formData.parameters.scenarioContext) ||
								null
							}
							getOptionLabel={option => option.label}
							getOptionValue={option => option.value}
							isDisabled={formData.useAllReportIndividualParameters}
							isLoading={!scenarios.length}
						/>
					</div>
				</div>

				<div className={styles.formGroup}>
					<div className={cn(styles.selectWrapper)}>
						<label className={cn('theme-label', styles.label)}>Funding Vehicle</label>
						<KiSelect
							classNamePrefix="aut-select"
							closeOnSelect={true}
							onChange={value => handleFundingVehicleIdChange(value._id)}
							options={fundingVehicles}
							value={fundingVehicles.find(item => item._id === formData.parameters.fundingVehicleId)}
							getOptionLabel={option => option.name}
							getOptionValue={option => option._id}
							error={validationErrors.parameters.fundingVehicleId}
							isDisabled={formData.useAllReportIndividualParameters}
						/>
					</div>
				</div>

				<div className={styles.formGroup}>
					<div className={cn(styles.selectWrapper)}>
						<label className={cn('theme-label', styles.label)}>Output Type</label>
						<KiSelect
							classNamePrefix="aut-select"
							closeOnSelect={true}
							isClearable={false}
							onChange={value => handleOutputTypeChange(value.value)}
							options={outputTypes}
							value={outputTypes.find(item => item.value === formData.parameters.outputType)}
							isDisabled={formData.useAllReportIndividualParameters}
						/>
					</div>
				</div>

				<div className={styles.formGroup}>
					<KiCheckbox
						name="saveAsDefaults"
						checked={formData.saveAsDefaults}
						label={`Save as Defaults${reportGroup ? ' for This Group' : ''}`}
						onChange={value => handleSaveAsDefaultsChange(value)}
						className={styles.checkbox}
						labelClassName={styles.label}
					/>
				</div>

				<p className={styles.hint}>The results are to be available in the Archive Tab</p>
			</form>
		</KiModal2>
	);
};

ReportExecutionModal.propTypes = {
	reportDefinition: PropTypes.object,
	reportGroup: PropTypes.object,
	onExecute: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onChangeReportGroup: PropTypes.func,
};

export default ReportExecutionModal;
